import React, { Component } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "./screens/Login";
import DownloadPage from "./screens/DownloadPage";
import NotFound from "./screens/NotFound";
//import { isAuthenticated } from "./repository";


class Router extends Component {
  render() {
    return (
      <BrowserRouter>
        {/* Configuración de Rutas y Páginas */}
        <Switch>      
          <Route exact path="/" component={Login} />     
          <Route exact path="/DownloadPage" component={DownloadPage}/>     
          <Route  path='*' component={NotFound} />  
        </Switch>
      </BrowserRouter>
    );
  }
}

//const auth = isAuthenticated();

// A wrapper for <Route> that redirects to the login
// screen if you're not yet authenticated.

/*
function PrivateRoute({ children, ...props }) {
  //console.log("auth: " + auth);
  return (
    <Route {...props} render={({location}) =>   ( children ) }/>
  );*/
  
  /*
  return (
    <Route
      {...props}
      render={({ location }) =>
        auth ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      }
    />
  );
  */
 
//}

export default Router;
