import React from "react";

const Footer = () => {

    return (
      <footer id="main-footer" className="bg-dark text-white mt-5 p-1">
        <div className="container">
          <div className="row">
            <div className="col">
              <p className="text-center">
                Copyright &copy;                
                GRUPO GOVI&nbsp;-{" "}
                <span id="yearFooter">{new Date().getFullYear()}</span>
              </p>
            </div>
          </div>
        </div>
      </footer>
    );
  }


export default Footer;
