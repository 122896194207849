import React, { useState, useEffect, useRef, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";
import Logo from "../images/govinet.png";
import Footer from "../components/Footer";
import axios from "axios";
import Global from "../components/Global";

const DownloadPage = (props) => {
  const [, setloading] = useState(0);
  const [Factura, setFactura] = useState("");
  const [RFC, setRFC] = useState("");
  const [, setForceUpdate] = useState(0);
  const [XmlValido, setXmlValido] = useState(false);
  const [ruta] = useState(sessionStorage.getItem("ruta"));
  const [Cedis] = useState(sessionStorage.getItem("Cedis"));

  useEffect(() => {
    setFactura(sessionStorage.getItem("Factura"));
    setRFC(sessionStorage.getItem("RFC"));
    setXmlValido(true);
    // eslint-disable-next-line
    //setRuta(""+window.location.origin + "/Facturas/"+Factura+ "-" + RFC + ".xml");
    // eslint-disable-next-line
  }, []);

  const submitLogin = (event) => {
    event.preventDefault();
    setloading(1);
    setForceUpdate(1);
    //if (validator.current.allValid()){
    var dirname = ruta;
    console.log(dirname);
    axios
      .get(dirname)
      .then((response) => {
        if (response.status === 200) {
          setXmlValido(true);
          // setRuta(dirname);
        }
      })
      .catch(() => {
        Swal("Aviso", "No se encontró información", "info");
        setXmlValido(false);
      });
    setloading(0);
  };

  useEffect(() => {
    validator.current.hideMessages();
    setForceUpdate(0);
  }, []);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );

  return (
    <div>
      {/* NAVBAR */}
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark p-0">
        <div className="container">
          <div className="navbar-brand">&nbsp;GOVI Suspensión Automotriz</div>
        </div>
      </nav>

      {/* HEADER */}
      <header id="main-header" className="py-1 bg-warning text-black">
        <div className="container">
          <div className="row">
            <div className="col-md-0">
              &nbsp;
              <img src={Logo} width="78%" height="auto" alt="GoviNet" />
            </div>
            <div className="col-md-0 mt-1">
              <h2 className="float-left">&nbsp;Descargar Archivos</h2>
            </div>
          </div>
        </div>
      </header>
      <section id="login">
        <div className="container">
          <div className="row">
            <div className="col-md-10 mx-auto mt-5">
              <div className="card">
                <div className="card-header">
                  <h6 className="text-center">
                    Descarga de Factura Electrónica -{" "}
                    {Cedis === "true" && <Fragment>Mayoreo</Fragment>}
                    {Cedis === "false" && <Fragment>Sucursal</Fragment>}
                  </h6>
                </div>
             
                <div className="card-body ">
                  <form onSubmit={submitLogin}>
                    <div className="row">
                      <table className="table table-striped">
                        <tr>
                          <td className="text-right">
                            <label htmlFor="usuario">
                              <strong>Factura:</strong>
                            </label>
                          </td>
                          <td className="text-left">
                            <label htmlFor="usuario">{Factura}</label>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right">
                            <label htmlFor="usuario">
                              <strong>RFC:</strong>
                            </label>
                          </td>
                          <td className="text-left">
                            <label htmlFor="usuario">{RFC}</label>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right">
                            <label htmlFor="usuario">
                              <strong>Archivo XML:</strong>
                            </label>
                          </td>
                          <td className="text-left">
                            <label htmlFor="usuario">
                              {Factura + "-" + RFC + ".xml"}
                            </label>
                          </td>
                        </tr>
                        <tr>
                          <td className="text-right">
                            <label htmlFor="usuario">
                              <strong>Archivo PDF:</strong>
                            </label>
                          </td>
                          <td className="text-left">
                            <label htmlFor="usuario">
                              {Factura + "-" + RFC + ".pdf"}
                            </label>
                          </td>
                        </tr>
                      </table>
                    </div>
                    {XmlValido === true && (
                      <div className="text-center mt-3">
                        <Fragment>
                          <div className="row justify-content-center ">
                            <div className="col-md-3 mt-1 ">
                              <a
                                className="btn btn-block btn-primary  mb-2"
                                href={ruta}
                                download={Factura + "-" + RFC + ".xml"}
                              >
                                <h6>
                                  <i class="fas fa-file-download"></i> Descargar
                                  XML
                                </h6>
                              </a>
                            </div>

                            {Cedis === "false" && (
                              <Fragment>
                                <div className="col-md-3 mt-1">
                                  <a
                                    className="btn btn-block btn-danger mb-2"
                                    href={
                                      Global.apiRESTUrlNET +
                                      "/GetReporte/" +
                                      Factura +
                                      "-" +
                                      RFC
                                    }
                                  >
                                    <h6>
                                      <i class="fas fa-file-download"></i>{" "}
                                      Descargar PDF
                                    </h6>
                                  </a>
                                </div>
                              </Fragment>
                            )}

                            {Cedis === "true" && (
                              <Fragment>
                                <div className="col-md-3 mt-1">
                                  <a
                                    className="btn btn-block btn-danger mb-2"
                                    href={ruta.replace("xml", "pdf")}
                                    download={Factura + "-" + RFC + ".pdf"}
                                  >
                                    <h6>
                                      <i class="fas fa-file-download"></i>{" "}
                                      Descargar PDF
                                    </h6>
                                  </a>
                                </div>
                              </Fragment>
                            )}

                            <div className="col-md-3 mt-1 ">
                              <a
                                className="btn btn-block btn-warning mb-2"
                                href={"/"}
                              >
                                {" "}
                                <h6>
                                  {" "}
                                  <i class="fa fa-arrow-left"></i> Regresar{" "}
                                </h6>{" "}
                              </a>
                            </div>
                          </div>
                        </Fragment>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </section>

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default DownloadPage;
