import React, { useState, useEffect, useRef, Fragment } from "react";
import SimpleReactValidator from "simple-react-validator";
import Swal from "sweetalert";
import Logo from "../images/govinet.png";
import Footer from "../components/Footer";
import axios from "axios";
//import DownloadPage from './DownloadPage';

const Login = (props) => {
  const [loading, setloading] = useState(0);
  const [Factura, setFactura] = useState("");
  const [RFC, setRFC] = useState("");
  const [, setForceUpdate] = useState(0);
  const [XmlValido, setXmlValido] = useState(false);
  const [, setRuta] = useState("");
  const [Cedis, setCedis] = useState(false);
  const [Sucursal, setSucursal] = useState(true);

  useEffect(() => {
    validator.current.hideMessages();
    if (XmlValido) {
      sessionStorage.setItem("Factura", Factura);
      sessionStorage.setItem("RFC", RFC);
      sessionStorage.setItem("Cedis", Cedis);

      var ruta = window.location + "DownloadPage";
      window.location = ruta;
    } else {
      sessionStorage.clear();
    }
    // eslint-disable-next-line
  }, [XmlValido]);

  const onChecked = (e) => {
    if (e.target.name === "rdoCedis") {
      setCedis(true);
      setSucursal(false);
    } else if (e.target.name === "rdoSucursal") {
      setCedis(false);
      setSucursal(true);
    }
  };
  const submitLogin = (event) => {
    event.preventDefault();
    setloading(1);
    //  console.log(Cedis);
    if (validator.current.allValid()) {
      var dirname;

      if (Cedis) {
        dirname =
          "" + window.location.origin + "/sap/" + Factura + "-" + RFC + ".xml";
      } else {
        dirname =
          "" +
          window.location.origin +
          "/Facturas/" +
          Factura +
          "-" +
          RFC +
          ".xml";
      }
      axios
        .get(dirname)
        .then((response) => {
          if (response.status === 200) {
            var datos = response.data.toString();
            if (datos.includes("xml")) {
              setRuta(dirname);
              sessionStorage.setItem("ruta", dirname);

              if (Cedis) {
                sessionStorage.setItem("Cedis", "true");
              } else {
                sessionStorage.setItem("Cedis", "false");
              }
              setXmlValido(true);
              //  window.location = "DownloadPage";
            } else {
              Swal("Aviso", "No se encontró información", "info");
            }
          } else {
            Swal("Aviso", "No se encontró información", "info");
          }
        })
        .catch((Ex) => {
          Swal("Aviso", "No se encontró información", "info");
          setXmlValido(false);
          console.log(Ex.message);
        });
    } else {
      validator.current.showMessages();
      Swal("Aviso", "Favor de validar la información ingresada.", "info");
    }
    setloading(0);
  };

  useEffect(() => {
    validator.current.hideMessages();
    setForceUpdate(0);
  }, []);

  const validator = useRef(
    new SimpleReactValidator({
      element: (message) => (
        <span className="text-danger">
          <i className="fa fa-exclamation-circle" aria-hidden="true" />
          {` ${message}`}
        </span>
      ),
      messages: {
        default: "Campo Requerido",
      },
    })
  );
  return (
    <div>
      {/* NAVBAR */}
      <nav className="navbar navbar-expand-sm navbar-dark bg-dark p-0">
        <div className="container">
          <div className="navbar-brand">&nbsp;GOVI Suspensión Automotriz</div>
        </div>
      </nav>

      {/* HEADER */}
      <header id="main-header" className="py-1 bg-warning text-black">
        <div className="container">
          <div className="row">
            <div className="col-md-0">
              &nbsp;
              <img src={Logo} width="70%" height="auto" alt="GoviNet" />
            </div>
            <div className="col-md-0 mt-1">
              <h3 className="float-left">&nbsp;Facturación Electrónica</h3>
            </div>
          </div>
        </div>
      </header>

      {/* LOGIN */}
      <section id="login">
        <div className="container">
          <div className="row">
            <div className="col-md-6 mx-auto mt-5">
              <div className="card">
                <div className="card-header">
                  <h6 className="text-center">
                    Ingrese los siguientes datos de su Factura
                  </h6>
                </div>
                <div className="card-body">
                  <form onSubmit={submitLogin}>
                    <div className="form-group">
                      <label htmlFor="usuario">Factura</label>
                      <input
                        type="text"
                        name="factura"
                        className="form-control"
                        onChange={(e) => setFactura(e.target.value)}
                        defaultValue={Factura}
                        maxLength="20"
                        autoComplete="off"
                      />
                      {validator.current.message(
                        "factura",
                        Factura,
                        "required|string"
                      )}
                    </div>
                    <div className="form-group">
                      <label htmlFor="password">RFC</label>
                      <input
                        type="text"
                        name="rfc"
                        className="form-control"
                        onChange={(e) => setRFC(e.target.value)}
                        defaultValue={RFC}
                        maxLength="20"
                      />
                      {validator.current.message("rfc", RFC, "required|string")}
                    </div>
                    <div className="form-group">
                      <label htmlFor="tipo Negocio" className="">
                        Tipo de Factura
                      </label>
                      <div className="row ">
                        <div className="col-8">
                          <div className="row">
                            <div className="col-6">
                              <label>
                                <input
                                  type="radio"
                                  name="rdoSucursal"
                                  className="form-group"
                                  onChange={(e) => {
                                    onChecked(e);
                                  }}
                                  checked={Sucursal}
                                />
                                {"   "} Sucursal
                              </label>
                            </div>
                            <div className="col-6">
                              <label>
                                <input
                                  type="radio"
                                  name="rdoCedis"
                                  className="form-group"
                                  checked={Cedis}
                                  onChange={(e) => {
                                    onChecked(e);
                                    setForceUpdate(1);
                                  }}
                                />{" "}
                                {"    "}Mayoreo
                              </label>
                            </div>
                           
                          </div>
                        </div>
                      </div>
                    </div>

                    {loading === 0 && XmlValido === false && (
                      <Fragment>
                        <div className="row">
                          <div className="col-md ">
                            <button
                              value="Aceptar"
                              className="btn btn-warning btn-block mt-3"
                              onClick={(e) => {
                                // e.preventDefault(e);
                                submitLogin(e);
                              }}
                            >
                              Aceptar
                            </button>
                          </div>
                          <div className="col-md">
                            <button
                              className="btn btn-secondary btn-block mt-3"
                              onClick={(e) => {
                                e.preventDefault(e);
                                window.location = "https://grob.mx";
                              }}
                            >
                              Cancelar{" "}
                            </button>
                          </div>
                        </div>
                      </Fragment>
                    )}

                    {loading === 1 && (
                      <div className="text-center mt-3">
                        <h6>Cargando...</h6>
                        <div
                          className="spinner-border text-warning"
                          role="status"
                          aria-hidden="true"
                        ></div>
                      </div>
                    )}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* FOOTER */}
      <Footer />
    </div>
  );
};

export default Login;
