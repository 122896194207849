var Global = {  
  //API REST Desarrollo
  //apiRESTUrlNET: "http://localhost:58698/api", //ReportePDF

  //API REST Produccion
  apiRESTUrlNET: "https://factuacion-govi-net.azurewebsites.net/api", //ReportePDF
  
};

export default Global;
